import PropTypes from 'prop-types';
import React from 'react';
import './Error.css';

function Error(props) {
  const {
    error,
    setError,
  } = props;
  return (
    <div className={`error ${error ? 'show' : ''}`}>
      <div className="errorMessage">
        {error}
        <button className="ok" type="button" onClick={() => setError(undefined)}>OK</button>
      </div>
    </div>
  );
}

Error.propTypes = {
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

Error.defaultProps = {
  error: undefined,
};

export default Error;
