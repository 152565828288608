import React, { useContext, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import { useNavigate, useParams } from 'react-router-dom';
import done from '../../assets/animations/done.json';
import AppLogo from '../../components/atoms/applogo/AppLogo';
import Button from '../../components/atoms/button/Button';
import Container from '../../components/atoms/container/Container';
import Footer from '../../components/atoms/footer/footer';
import Input from '../../components/atoms/input/Input';
import QRZoomModal from '../../components/atoms/modal/QRZoomModal';
import SuccessAnimation from '../../components/atoms/successAnimation/SuccessAnimation';
import { AuthContext } from '../../contexts/AuthProvider';
import { getBookingInfo } from '../../services';
import './home.css';
import Error from '../../components/atoms/error/Error';

const VERMIETUNG_STANDORT_WOHNMOBILSTELLPLATZ = 2;

function HomePage() {
  const { setLoader } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [ticketNumber, setTicketNumber] = useState(id || '');
  const [showModal, setShowModal] = useState(false);
  const [bookingPaid, setBookingPaid] = useState(false);
  const [error, setError] = useState(undefined);

  const handleScan = (data) => {
    if (data) {
      const tktNumber = data.split('/').pop();
      setTicketNumber(tktNumber);
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (bookingPaid) {
      setTimeout(() => setBookingPaid(false), 2000);
      setTicketNumber('');
    }
  }, [bookingPaid]);

  const handleError = () => {
    setTicketNumber('');
    setShowModal(false);
  };

  const isWohnmobilStellplatz = (bookingInfo) => {
    const isWomo = bookingInfo.positionen.some((p) => {
      const standort = p.vermietung.standort_id;

      return standort === VERMIETUNG_STANDORT_WOHNMOBILSTELLPLATZ;
    });

    return isWomo;
  };

  const fetchTicketDetails = async () => {
    setLoader(true);
    try {
      const bookingInfo = await getBookingInfo(ticketNumber);
      setLoader(false);
      if (bookingInfo) {
        if (isWohnmobilStellplatz(bookingInfo)) {
          setError('Bitte scannen Sie den QRCode an der Säule des Wohnmobilstellplatzes.');
          setTicketNumber('');
          return;
        }

        const isBookingLocked = bookingInfo.sperre_kz || false;
        if (isBookingLocked) {
          setError('Die Reservierung ist gesperrt.');
          setTicketNumber('');
          return;
        }

        const isBookingCancelled = bookingInfo.positionen.some((p) => p.status === 0);
        if (!isBookingCancelled) {
          setError('Die Reservierung ist storniert.');
          setTicketNumber('');
          return;
        }

        const isCheckOut = bookingInfo.positionen.some((p) => p.vermietung.checkout);
        if (isCheckOut) {
          setBookingPaid(true);
        } else {
          navigate('/summary', {
            state: {
              bookingInfo,
              ticketNumber,
              checkoutDate: null,
              checkoutAmount: 0,
            },
          });
        }
      } else {
        setError('Es ist ein Fehler beim Laden der Daten aufgetreten.');
      }
    } catch (err) {
      setError('Es ist ein Fehler beim Laden der Daten aufgetreten.');
      setLoader(false);
    }
  };

  return (
    <>
      {
                showModal
                && (
                <QRZoomModal onModalClose={() => setShowModal(false)}>
                  <QrReader
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                  />
                </QRZoomModal>
                )
            }
      <Container>
        <Error error={error} setError={setError} />
        <AppLogo />
        {bookingPaid ? <SuccessAnimation animation={done} />
          : (
            <div className="page-container">
              <div className="home-container">
                <h1>Herzlich willkommen</h1>
                <p className="description-home-screen">
                  Bitte geben Sie ihre Parkticket-ID in das folgende Feld
                  ein oder scannen Sie den Barcode mit der Handykamera.
                </p>
                <div className="input-wrapper">
                  <Input
                    type="number"
                    placeholder="Ticketnummer"
                    name="ticketnummer"
                    value={ticketNumber}
                    handleInputChange={(event) => setTicketNumber(event.target.value)}
                  />
                  <Button label="" handleClick={() => setShowModal(true)} />
                </div>
                <Button
                  handleClick={fetchTicketDetails}
                  label="jetzt prüfen"
                  disabled={!ticketNumber}
                />
              </div>
            </div>
          )}
        <Footer />
      </Container>
    </>
  );
}

export default HomePage;
